import { CacheLocation }  from '@auth0/auth0-spa-js'

const cacheLocation: CacheLocation = 'localstorage'

const common = {
  connection: "Username-Password-Authentication",
  redirectUri: `${window.location.origin}/portal${window.location.search}`,
  responseType: "token id_token",
  scope: "openid",
  ripplesKey:"f309acb6-ed7f-484c-8f85-da6fc0154574",
  returnTo: `${window.location.origin}/portal${window.location.search}`,
  useRefreshTokens: true,
  cacheLocation
};

export const config: any = {
  TRANSLATIONS: {
    url: 'https://api.locize.app',
    apiKey: '88ce801f-7861-4e1e-aa72-66c86c149c5c',
    projectId: '0e4ba6e0-a896-4f7d-ae3c-0414be1b9350',
    version: 'latest',
    namespace: 'Mobile portal',
  },
  DEVELOPMENT: {
    PORTAL: {
      GRAPHQL_SERVER_URL: "https://my.dev.coffeeripples.com/graphql",
      WEBAPP_URL: "https://webapp.dev.coffeeripples.com",
      WEBAPP_API_URL: "https://webapp.dev.coffeeripples.com/api/v1",
      REST_API_URL: "https://my.dev.coffeeripples.com/api",
      WEBAPP_TEMPLATE_ID: "64edaecec4f4130013fa536e",
    },
    IDENTITY: {
      REST_API_URL: "https://identity.dev.coffeeripples.com/api",
    },
    AUTH: {
      ...common,
      domain: "login-dev.drinkripples.com",
      clientId: "ktX9AOmKBI6NxrzFBsGzCOB4VHSheCYm",
      audience: "https://my-dev.drinkripples.com"
    },
    RIPPLES_ID: '6208dd88620d00589b06cd3e'
  },
  STAGING: {
    PORTAL: {
      GRAPHQL_SERVER_URL: "https://my.stg.coffeeripples.com/graphql",
      WEBAPP_URL: "https://webapp.stg.coffeeripples.com",
      WEBAPP_API_URL: "https://webapp.stg.coffeeripples.com/api/v1",
      REST_API_URL: "https://my.stg.coffeeripples.com/api",
      WEBAPP_TEMPLATE_ID: "62a9aa23222afd0011dbd62a"
    },
    IDENTITY: {
      REST_API_URL: "https://identity.stg.coffeeripples.com/api",
    },
    AUTH: {
      ...common,
      domain: "login-st.drinkripples.com",
      clientId: "jz4F6vBWV0t4re7QqUU327P6xbttBUtX",
      audience: "https://my-st.drinkripples.com"
    },
    RIPPLES_ID: '61dad4efb0cefd367b8abc66'
  },
  PRODUCTION: {
    PORTAL: {
      GRAPHQL_SERVER_URL: "https://my.drinkripples.com/graphql",
      WEBAPP_URL: "https://webapp.drinkripples.com",
      WEBAPP_API_URL: "https://rpl-webapp.azurewebsites.net/api/v1",
      REST_API_URL: "https://my.drinkripples.com/api",
      WEBAPP_TEMPLATE_ID: "62a9aad56f3b9e0011b395b4"
    },
    IDENTITY: {
      REST_API_URL: "https://identity.service.drinkripples.com/api",
    },
    AUTH: {
      ...common,
      domain: "login.drinkripples.com",
      clientId: "4Imc39xsnTfunNZvtSlX8WsWLY5oKJSr",
      audience: "https://my.drinkripples.com"
    },
    RIPPLES_ID: '5de8b8f3e7db1e578cb44b44'
  }
}

export default config